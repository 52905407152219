import { Col, Container, PageBanner, Row, SocialButton, Thumbnail, Text } from '@zigurous/forge-react'; // prettier-ignore
import { Link as GatsbyLink, graphql } from 'gatsby';
import React from 'react';
import { Page } from '../components';
import { trackEvent } from '../firebase/analytics';
import type { GameData } from '../types';

const metadata = {
  url: 'https://zigurous.com/games',
  title: 'Zigurous • Games',
  description:
    'Zigurous is working on their next game Zeek Will Freak, a procedurally generated action RPG game where players will fight for survival from the torments of their own mind.',
};

interface GamesProps {
  data: {
    json: {
      games: GameData[];
    };
  };
  location: Location;
}

export default function Games({ data, location }: GamesProps) {
  const { games } = data.json;
  return (
    <Page className="games" location={location} metadata={metadata}>
      <PageBanner className="bg-surface-1">
        <Container fluid>
          <Row className="align-center">
            <Col className="mb-xxl" size={12} md={6} lg={5}>
              <Text
                as="div"
                className="ml-xxxs"
                marginBottom="xs"
                type="subtitle"
              >
                Zeek Will Freak
              </Text>
              <Text marginBottom="lg" nowrap type="title-lg">
                In Development
              </Text>
              <SocialButton
                link="twitch"
                onClick={() => {
                  trackEvent('social_button', {
                    content_type: 'button',
                    event_category: 'engagement',
                    event_label: 'Watch on Twitch',
                  });
                }}
                shape="pill"
                size="md"
                variant="outline"
              >
                Watch on Twitch
              </SocialButton>
            </Col>
            <Col>
              <Text type="body-lg">
                Zeek Will Freak is a procedurally generated action RPG where
                players will fight for survival from the torments of their own
                mind. The game tells a deeply personal story of a character
                named Zeek who must learn to balance their inner forces of light
                and dark. Are you strong enough to withstand the evils lurking
                in your own head, or will you come out the other end a broken
                monster?
              </Text>
            </Col>
          </Row>
        </Container>
      </PageBanner>
      <Container className="mt-xxxl" fluid>
        <Row>
          <Col>
            <Text marginBottom="md" type="title">
              Legacy Games
            </Text>
          </Col>
        </Row>
        <Row>
          {games.map((game, index) => (
            <Col className="p-lg" key={game.id} size={12} md={6} lg={4}>
              <Thumbnail
                animated
                animation={['fade-in', 'slide-in']}
                aria-label={game.title}
                as={GatsbyLink}
                image={{
                  alt: game.title,
                  src: game.image.sharp.original.src,
                  width: game.image.sharp.original.width,
                  height: game.image.sharp.original.height,
                }}
                index={index}
                onClick={() => {
                  trackEvent('thumbnail', {
                    content_type: 'image',
                    event_category: 'engagement',
                    event_label: game.title,
                    item_id: game.id,
                  });
                }}
                to={`/games/${game.id}`}
              />
            </Col>
          ))}
        </Row>
      </Container>
    </Page>
  );
}

export const query = graphql`
  query Games {
    json: allGamesJson {
      games: nodes {
        id: jsonId
        title
        subtitle
        image {
          sharp: childImageSharp {
            original {
              src
              width
              height
            }
          }
        }
      }
    }
  }
`;
